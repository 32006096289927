import React from 'react';
import sprout from '../../assets/images/partners/sprout.png';
import telegraaf from '../../assets/images/partners/telegraaf.png';
import emerce from '../../assets/images/partners/emerce.png';
import npo from '../../assets/images/partners/npo.png';
import volkskrant from '../../assets/images/partners/volkskrant.png';

const PartnersSection = () => {
  return (
    <section className="section-2 u-section-padding">
      <div className="container">
        <div className="section-2__content">
          <img src={sprout} alt="sprout" className="section-2__img" />
          <img src={telegraaf} alt="telegraaf" className="section-2__img" />
          <img src={volkskrant} alt="volkskrant" className="section-2__img" />
          <img src={npo} alt="npo" className="section-2__img" />
          <img src={emerce} alt="emerce" className="section-2__img" />
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
