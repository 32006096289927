import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { getFlowButtonHandler } from '../Dialog';

const StepsSection = () => {
  const { t } = useTranslation('home');
  return (
    <section className="section-11 u-section-padding">
      <div className="container">
        <div className="section-11__content">
          <div className="section-11__block u-spacing-large">
            <h2 className="section-11__title u-title-primary">
              {t('StepsSection.title_1', 'Start automating your money today')}
            </h2>
            <div className="section-11__text u-text-primary">
              {t(
                'StepsSection.title_2',
                'Setup your first Flow in minutes and watch your money grow.'
              )}
            </div>
          </div>
          <div className="section-11__block">
            <div className="section-11__item u-spacing-normal">
              <div className="section-11__num section-11__num--blue">1</div>
              <div className="section-11__info u-color-primary">
                {t('StepsSection.steps.0', 'Connect your bank account')}
              </div>
            </div>
            <div className="section-11__item u-spacing-normal">
              <div className="section-11__num section-11__num--pink">2</div>
              <div className="section-11__info u-color-primary">
                {t('StepsSection.steps.1', 'Setup your first Flow')}
              </div>
            </div>
            <div className="section-11__item u-spacing-normal">
              <div className="section-11__num section-11__num--green">3</div>
              <div className="section-11__info u-color-primary">
                {t('StepsSection.steps.2', 'Let your money grow')}
              </div>
            </div>
          </div>
          <button
            onClick={() => getFlowButtonHandler()}
            className="btn btn--primary"
          >
            {t('StepsSection.buttonTitle', 'Get the app')}
          </button>
        </div>
      </div>
    </section>
  );
};

export default StepsSection;
