import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import flowhand from '../../assets/images/pics/flowhand.png';
import { getFlowButtonHandler } from '../Dialog';

const HeroSection = () => {
  const { t } = useTranslation('home');

  return (
    <section className="section-1">
      <div className="container">
        <div className="section-1__content">
          <div className="section-1__block u-spacing-large">
            <h2 className="section-1__title u-title-primary">
              {t('HeroSection.title', 'Save money. Effortlessly.')}
            </h2>
            <div className="section-1__text u-text-primary">
              {t(
                'HeroSection.description',
                `Automate saving, budgeting and investing so you can feel relaxed
              and confident about your money.`
              )}
            </div>
            <button
              className="btn btn--primary"
              onClick={() => getFlowButtonHandler()}
            >
              {t('HeroSection.buttonTitle', 'Get the app')}
            </button>
          </div>
          <img
            src={flowhand}
            width="500"
            height="687"
            alt="img"
            className="section-1__img"
          />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
