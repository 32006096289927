import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import actionsEN from '../../assets/images/pics/actions-en.png';
import actionsNL from '../../assets/images/pics/actions-nl.png';

const ActionsSection = () => {
  const { t, i18n } = useTranslation('home');
  return (
    <section className="section-6 u-section-padding">
      <div className="container">
        <div className="section-6__content">
          <img
            src={i18n.language === 'en' ? actionsEN : actionsNL}
            alt="triggers"
            className="section-6__img"
          />
          <div className="section-6__block u-spacing-normal">
            <h3 className="section-6__title u-title-secondary">
              {t('ActionsSection.title', 'Choose how much should go where')}
            </h3>
            <div className="section-6__text u-text-primary">
              {t(
                'ActionsSection.description',
                `Set up unlimited Flows and move your money where it matters
              without any work.`
              )}
            </div>
            <a
              href="/all-actions"
              rel="noreferrer"
              className="section-6__link u-link-primary"
            >
              {t('ActionsSection.linkTitle', 'View all actions')}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ActionsSection;
