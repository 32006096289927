import React from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

const BenefitsSection = () => {
  const { t } = useTranslation('home');
  return (
    <section className="section-9 u-section-padding">
      <div className="container">
        <div className="section-9__content">
          <div className="section-9__block u-spacing-large">
            <h2 className="section-9__title u-title-primary">
              <Trans
                defaults="<0>Privacy friendly</0> and secure"
                i18nKey="home:BenefitsSection.title"
                components={[<span className="u-color-secondary" />]}
              />
            </h2>
            <div className="section-9__text u-text-primary">
              {t(
                'BenefitsSection.description',
                `It’s your money so no time to fool around. Keeping your data safe
              and private is our #1 priority.`
              )}
            </div>
          </div>
          <div className="section-9__block">
            <div className="section-9__row">
              <div className="section-9__col">
                <div className="section-9__logo section-9__logo--psd2"></div>
                <h3 className="section-9__subtitle u-color-primary">
                  {t('BenefitsSection.list.0.title', 'PSD2 Licensed')}
                </h3>
              </div>
              <div className="section-9__col">
                <div className="section-9__info u-color-gray">
                  <Trans
                    defaults="Look us up in the registers, we are regulated by the DNB. Read our <0>terms of use</0> to learn more about us."
                    i18nKey="home:BenefitsSection.list.0.description"
                    components={[
                      <a
                        href="/terms-of-use/"
                        rel="noreferrer"
                        className="u-color-secondary"
                      />,
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="section-9__row">
              <div className="section-9__col">
                <div className="section-9__logo section-9__logo--iso"></div>
                <h3 className="section-9__subtitle u-color-primary">
                  {t('BenefitsSection.list.1.title', 'ISO27001 Certified')}
                </h3>
              </div>
              <div className="section-9__col">
                <div className="section-9__info u-color-gray">
                  {t(
                    'BenefitsSection.list.1.description',
                    'We use bank grade security measures to protect your data and we are ISO27001 certified: a standard in information security.'
                  )}
                </div>
              </div>
            </div>
            <div className="section-9__row">
              <div className="section-9__col">
                <div className="section-9__logo section-9__logo--privacy"></div>
                <h3 className="section-9__subtitle u-color-primary">
                  {t('BenefitsSection.list.2.title', 'Privacy First')}
                </h3>
              </div>
              <div className="section-9__col">
                <div className="section-9__info u-color-gray">
                  <Trans
                    defaults="Our service is designed with you in mind, making sure your data is private and secure. We make money by <0>subscriptions</0>, not data."
                    i18nKey="home:BenefitsSection.list.2.description"
                    components={[
                      <a
                        href="/flow-subscriptions"
                        rel="noreferrer"
                        className="u-color-secondary"
                      />,
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="section-9__block u-spacing-small">
            <div className="u-text-primary">
              {t('BenefitsSection.subtitle', 'We’re here if you need us.')}
            </div>
            <a
              href="mailto:hi@flowyour.money"
              target="_self"
              className="section-9__link u-link-primary gist-open-chat"
            >
              {t('BenefitsSection.linkText', 'Talk to our humans')}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
