import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import triggersEN from '../../assets/images/pics/triggers-en.png';
import triggersNL from '../../assets/images/pics/triggers-nl.png';

const TriggersSection = () => {
  const { t, i18n } = useTranslation('home');
  return (
    <section className="section-5 u-section-padding">
      <div className="container">
        <div className="section-5__content">
          <div className="section-5__block u-spacing-normal">
            <h3 className="section-5__title u-title-secondary">
              {t('TriggersSection.title', 'Divide your money when it comes in')}
            </h3>
            <div className="section-5__text u-text-primary">
              {t(
                'TriggersSection.description',
                `Flow automatically moves your money across all your budgets,
                savings and investments, based on triggers you set up in the app.`
              )}
            </div>
            <a
              href="/all-triggers"
              rel="noreferrer"
              className="section-5__link u-link-primary"
            >
              {t('TriggersSection.linkTitle', 'View all triggers')}
            </a>
          </div>
          <img
            src={i18n.language === 'en' ? triggersEN : triggersNL}
            alt="triggers"
            className="section-5__img"
          />
        </div>
      </div>
    </section>
  );
};

export default TriggersSection;
