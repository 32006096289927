import React from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import banks from '../../assets/images/pics/floating-banks.png';
import { Link } from 'gatsby';

const BanksSection = () => {
  const { t } = useTranslation('home');
  return (
    <section className="section-3 u-section-padding">
      <div className="container">
        <div className="section-3__content">
          <div className="section-3__block u-spacing-normal">
            <h3 className="section-3__title u-title-secondary">
              <Trans
                defaults="Your bank needs <0>Flow</0>"
                i18nKey="home:BanksSection.title"
                components={[
                  <span className="section-3__subtitle u-color-secondary" />,
                ]}
              />
            </h3>
            <div className="section-3__text u-text-primary">
              {t(
                'BanksSection.description',
                ` Flow adds smart capabilities on top of your bank accounts. Connect
              your banks to Flow and get complete control over your money.`
              )}
            </div>
            <Link to="/banks" className="section-3__link u-link-primary">
              {t('BanksSection.linkTitle', 'View supported banks')}
            </Link>
          </div>
          <img src={banks} alt="banks" className="section-3__img" />
        </div>
      </div>
    </section>
  );
};

export default BanksSection;
