import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import jars from '../../assets/images/icons/jars.svg';
import kakeibo from '../../assets/images/icons/kakeibo.svg';
import profit from '../../assets/images/icons/profit.svg';
import salary from '../../assets/images/icons/salary-splitter.svg';
import vat from '../../assets/images/icons/vat.svg';
import oneCent from '../../assets/images/icons/one-cent.svg';

const MethodsSection = () => {
  const { t } = useTranslation('home');
  return (
    <section className="section-8 u-section-padding">
      <div className="section-8__card section-8__card--jars">
        <img src={jars} alt="jars" className="section-8__logo" />
        <h5 className="section-8__title">
          {t('MethodsSection.jars.title', 'Five Jars')}
        </h5>
        <div className="section-8__text">
          {t(
            'MethodsSection.jars.description',
            'Simple method using five jars'
          )}
        </div>
        <div className="section-8__label">
          {t('MethodsSection.jars.label', 'Budgetting')}
        </div>
      </div>
      <div className="section-8__card section-8__card--kakeibo">
        <img src={kakeibo} alt="kakeibo" className="section-8__logo" />
        <h5 className="section-8__title">
          {t('MethodsSection.kakeibo.title', 'Kakeibo')}
        </h5>
        <div className="section-8__text">
          {t(
            'MethodsSection.kakeibo.description',
            'Marie-Kondo method for money'
          )}
        </div>
        <div className="section-8__label">
          {t('MethodsSection.kakeibo.label', 'Budgeting')}
        </div>
      </div>
      <div className="section-8__card section-8__card--profit">
        <img src={profit} alt="profit" className="section-8__logo" />
        <h5 className="section-8__title">
          {t('MethodsSection.profit.title', 'Profit First')}
        </h5>
        <div className="section-8__text">
          {t(
            'MethodsSection.profit.description',
            'A new take on business finance'
          )}
        </div>
        <div className="section-8__label">
          {t('MethodsSection.profit.label', 'Freelance')}
        </div>
      </div>
      <div className="section-8__card section-8__card--salary">
        <img src={salary} alt="salarySplitter" className="section-8__logo" />
        <h5 className="section-8__title">
          {t('MethodsSection.salary.title', 'Salary Splitter')}
        </h5>
        <div className="section-8__text">
          {t(
            'MethodsSection.salary.description',
            'Split your salary as soon as it arrives'
          )}
        </div>
        <div className="section-8__label">
          {t('MethodsSection.salary.label', 'Budgetting')}
        </div>
      </div>
      <div className="section-8__card section-8__card--vat">
        <img src={vat} alt="vat" className="section-8__logo" />
        <h5 className="section-8__title">
          {t('MethodsSection.vat.title', 'VAT Splitter')}
        </h5>
        <div className="section-8__text">
          {t(
            'MethodsSection.vat.description',
            'Split the VAT of your invoices'
          )}
        </div>
        <div className="section-8__label">
          {t('MethodsSection.vat.label', 'Freelance')}
        </div>
      </div>
      <div className="section-8__card section-8__card--one-cent">
        <img src={oneCent} alt="oneCent" className="section-8__logo" />
        <h5 className="section-8__title">
          {t('MethodsSection.oneCent.title', 'One Cent Cha...')}
        </h5>
        <div className="section-8__text">
          {t(
            'MethodsSection.oneCent.description',
            'Day 1, one cent, Day 2, two cents...'
          )}
        </div>
        <div className="section-8__label">
          {t('MethodsSection.oneCent.label', 'Budgetting')}
        </div>
      </div>
    </section>
  );
};

export default MethodsSection;
