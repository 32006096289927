import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const AccountsSection = () => {
  const { t } = useTranslation('home');
  return (
    <section className="section-4 u-section-padding">
      <div className="section-4__row">
        <div className="section-4__card">
          <div className="section-4__img section-4__img--saving"></div>
          <div className="section-4__info">
            <h4 className="section-4__title">
              {t('AccountsSection.saving', 'Saving')}
            </h4>
            <div className="section-4__text">NL23 FLOW 8564 2323 56</div>
          </div>
        </div>
        <div className="section-4__card">
          <div className="section-4__img section-4__img--investing"></div>
          <div className="section-4__info">
            <h4 className="section-4__title">
              {t('AccountsSection.investing', 'Investing')}
            </h4>
            <div className="section-4__text">NL23 FLOW 3443 0033 02</div>
          </div>
        </div>
        <div className="section-4__card">
          <div className="section-4__img section-4__img--giving"></div>
          <div className="section-4__info">
            <h4 className="section-4__title">
              {t('AccountsSection.giving', 'Giving')}
            </h4>
            <div className="section-4__text">NL23 FLOW 4367 8643 12</div>
          </div>
        </div>
        <div className="section-4__card">
          <div className="section-4__img section-4__img--groceries"></div>
          <div className="section-4__info">
            <h4 className="section-4__title">
              {t('AccountsSection.groceries', 'Groceries')}
            </h4>
            <div className="section-4__text">NL23 FLOW 8564 2323 56</div>
          </div>
        </div>
      </div>
      <div className="section-4__row">
        <div className="section-4__card">
          <div className="section-4__img section-4__img--kids"></div>
          <div className="section-4__info">
            <h4 className="section-4__title">
              {t('AccountsSection.kids', 'Kids')}
            </h4>
            <div className="section-4__text">NL23 BUNQ 1682 1987 56</div>
          </div>
        </div>
        <div className="section-4__card">
          <div className="section-4__img section-4__img--shopping"></div>
          <div className="section-4__info">
            <h4 className="section-4__title">
              {t('AccountsSection.shopping', 'Online Shopping')}
            </h4>
            <div className="section-4__text">NL23 FLOW 0033 0234 43</div>
          </div>
        </div>
        <div className="section-4__card">
          <div className="section-4__img section-4__img--travel"></div>
          <div className="section-4__info">
            <h4 className="section-4__title">
              {t('AccountsSection.travel', 'Travel')}
            </h4>
            <div className="section-4__text">NL23 FLOW 8643 1243 67</div>
          </div>
        </div>
        <div className="section-4__card">
          <div className="section-4__img section-4__img--vat"></div>
          <div className="section-4__info">
            <h4 className="section-4__title">
              {t('AccountsSection.buffer', 'VAT Buffer')}
            </h4>
            <div className="section-4__text">NL23 FLOW 2323 8564 56</div>
          </div>
        </div>
        <div className="section-4__card">
          <div className="section-4__img section-4__img--housekeeping"></div>
          <div className="section-4__info">
            <h4 className="section-4__title">
              {t('AccountsSection.housekeeping', 'Housekeeping')}
            </h4>
            <div className="section-4__text">NL23 BUNQ 8564 2323 56</div>
          </div>
        </div>
      </div>
      <div className="section-4__row">
        <div className="section-4__card">
          <div className="section-4__img section-4__img--travel"></div>
          <div className="section-4__info">
            <h4 className="section-4__title">
              {t('AccountsSection.travel', 'Travel')}
            </h4>
            <div className="section-4__text">NL23 FLOW 8643 1243 67</div>
          </div>
        </div>
        <div className="section-4__card">
          <div className="section-4__img section-4__img--kids"></div>
          <div className="section-4__info">
            <h4 className="section-4__title">
              {t('AccountsSection.kids', 'Kids')}
            </h4>
            <div className="section-4__text">NL23 BUNQ 1682 1987 56</div>
          </div>
        </div>
        <div className="section-4__card">
          <div className="section-4__img section-4__img--shopping"></div>
          <div className="section-4__info">
            <h4 className="section-4__title">
              {t('AccountsSection.shopping', 'Online Shopping')}
            </h4>
            <div className="section-4__text">NL23 FLOW 0033 0234 43</div>
          </div>
        </div>
        <div className="section-4__card">
          <div className="section-4__img section-4__img--vat"></div>
          <div className="section-4__info">
            <h4 className="section-4__title">
              {t('AccountsSection.buffer', 'VAT Buffer')}
            </h4>
            <div className="section-4__text">NL23 FLOW 2323 8564 56</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountsSection;
