import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import pricing from '../../assets/images/pics/pricing.png';
import { Link } from 'gatsby';

const PackagesSection = () => {
  const { t } = useTranslation('home');
  return (
    <section className="section-10 u-section-padding">
      <div className="container">
        <div className="section-10__content">
          <div className="section-10__block u-spacing-normal">
            <h2 className="section-10__title u-title-primary">
              {t('PricingSection.title', 'Get started now, pick a plan later')}
            </h2>
            <div className="section-10__text u-text-primary">
              {t(
                'PricingSection.description',
                `Just fair and honest pricing. Simply choose a plan with the
              features that you want, or compare plans`
              )}
            </div>
            <Link to="/pricing" className="btn btn--secondary">
              {t('PricingSection.buttonTitle', 'View pricing')}
            </Link>
          </div>
          <img src={pricing} alt="pricing" className="section-10__img" />
        </div>
      </div>
    </section>
  );
};

export default PackagesSection;
