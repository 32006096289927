import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/SEO/seo';
import HeroSection from '../components/home/HeroSection';
import PartnersSection from '../components/home/PartnersSection';
import BanksSection from '../components/home/BanksSection';
import AccountsSection from '../components/home/AccountsSection';
import TriggersSection from '../components/home/TriggersSection';
import ActionsSection from '../components/home/ActionsSection';
import DiscoverSection from '../components/home/DiscoverSection';
import MethodsSection from '../components/home/MethodsSection';
import BenefitsSection from '../components/home/BenefitsSection';
import PricingSection from '../components/home/PricingSection';
import StepsSection from '../components/home/StepsSection';
import { useTranslation } from 'react-i18next';

const Home = (page: PageProps) => {
  const { t } = useTranslation('home');
  return (
    <Layout>
      <Seo page={page} homePage title={t('title') ?? 'Home page'} />
      <main className="page-home">
        <HeroSection />
        <PartnersSection />
        <BanksSection />
        <AccountsSection />
        <TriggersSection />
        <ActionsSection />
        <DiscoverSection />
        <MethodsSection />
        <BenefitsSection />
        <PricingSection />
        <StepsSection />
      </main>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
