import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import floatingCloudEN from '../../assets/images/pics/floating-cloud-en.png';
import floatingCloudNL from '../../assets/images/pics/floating-cloud-nl.png';

const DiscoverSection = () => {
  const { t, i18n } = useTranslation('home');
  return (
    <section className="section-7 u-section-padding">
      <div className="container">
        <div className="section-7__content">
          <div className="section-7__block u-spacing-normal">
            <h3 className="section-7__title u-title-primary">
              {t('DiscoverSection.title', 'Popular use cases')}
            </h3>
            <div className="section-7__text u-text-primary">
              {t(
                'DiscoverSection.description',
                `Flow is for everyone, from freelancers and business owners to
              young families and their grandparents. We collect the best money
              systems that you can use in one go.`
              )}
            </div>
            <a
              href="/all-money-methods"
              rel="noreferrer"
              className="section-7__link u-link-primary"
            >
              {t('DiscoverSection.linkTitle', 'View all Money Methods')}
            </a>
          </div>
          <div className="section-7__block">
            <img
              src={i18n.language === 'en' ? floatingCloudEN : floatingCloudNL}
              alt="floatingCloud"
              className="section-7__img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DiscoverSection;
